


export function numFormat(num:number | string):string {
	return num.toString().replace(/\d+/, function (n) {
		return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) {
			return $1 + ",";
		});
	})
}

export function editor_length(html_str:string):number {
	const reg = /<.+?>/g
	return html_str.replace(reg,'').length
}

export function dateToKakao(date:string):string{
	const arr = date.split('-')
	return arr[0] + '년' + arr[1] + '월' + arr[2] + '일'
}

export function dateToLast(date:string){
	const last = new Date(date).getTime()
	const now = new Date().getTime()
	if(now>last) return 'D-0'
	const lastTimestamp = last - now
	return 'D-'+Math.floor(lastTimestamp/(1000*60*60*24))
	
}

export function toFixed0(num:number | string){
	return Number(num).toFixed(0)
}