


























































































































































































































































































/* eslint-disable */
import { Vue, Component } from "vue-property-decorator";
import { banner, api_home, position, subject, pass } from "@/api";
import { winopen } from "@/util/other";
import { OtherModule } from "@/store/other";
import { numFormat } from "@/util/string";
@Component
export default class extends Vue {
  numFormat = numFormat
  phoneCarouselHeight = '2rem'

  banner_list: banner[] = [];

  evaluation_list: any[] = [];

  position_list: position[] = [];

  subject_list: subject[] = [];

  pass_list: pass[] = []

  async get_banner_list() {
    this.banner_list = await api_home.get_banner();
  }

  changeCarousel(index:number){
    this.phoneCarouselHeight = (this.$refs[`phone${index}`] as any)[0].offsetHeight/100 + 'rem'
  }

  async get_pass_list() {
    const res = await api_home.get_pass({ page: 1, page_size: 6 });
    this.pass_list = res.results
  }

  //   async get_evaluation_list() {
  //     this.evaluation_list = await api_home.get_evaluation();
  //   }

  async get_position_list() {
    const res = await api_home.get_position({ page: 1, page_size: 6, is_hot: 1 });
    this.position_list = res.results;
  }

  async get_subject_list() {
    const res = await api_home.get_subject({ page: 1, page_size: 6, is_hot: 1 });
    this.subject_list = res.results;
  }

  async created() {
    await Promise.all([
      this.get_banner_list(),
      //   this.get_evaluation_list(),
      this.get_position_list(),
      this.get_subject_list(),
      this.get_pass_list()
    ]);
  }

  open(url: string | null) {
    if (url === null) {
      return;
    }
    winopen(url);
  }

  get is_mobile():boolean {
    return OtherModule.is_mobile;
  }
}
